<template>
  <div class="mt-5 mb-5">
    <b-container>
      <div v-if="getProductsCart.length >0">
        <div v-for="(store, key) in getProductsCart" :key="key">
          <component
              :productItem="store"
              :is="selectedView"></component>
        </div>
      </div>
      <div v-else class="mb-5">
          <empty-cart></empty-cart>
      </div>
    </b-container>
    </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import largeCart from '@/modules/servicePages/marketplace/components/cart/largeCart'
import smallCart from '@/modules/servicePages/marketplace/components/cart/smallCart'
import emptyCart from '@/modules/servicePages/marketplace/components/cart/emptyCart'
import marketplace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  name: 'cart',
  components: { largeCart, smallCart, emptyCart },
  props: ['productItem'],
  data () {
    return {
      loadingStore: false,
      products: [],
      selectedView: 'largeCart'
    }
  },
  computed: {
    getProductsCart () {
      return this.$store.getters['cart/getProductCart'].length > 0 ? this.$store.getters['cart/getProductCart'] : localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []
    }
  },
  methods: {
    getProducts () {
      this.loadingStore = true
      marketplace.getProductCart().then(res => {
        /*  eslint-disable  */
        let carts = []
        res.data.data.forEach(product => {
          if (product.product) {
            const getStoreId = carts.findIndex(store => store.store_info.id === product.store.id)
            if (getStoreId > -1) {
              carts[getStoreId].store_products.push({
                ...product.product,
                store_id: product.store.id,
                quantityInCart: Number(product.qty),
                totalProductPrice: Number(product.qty) * product.product.price_info.final_price,
              })
              carts[getStoreId].store_info = {
                ...carts[getStoreId].store_info,
                totalFees : Number(carts[getStoreId].store_info.totalFees) + (product.product.price_info.final_price  * Number(product.qty))
              }
            } else {
              carts.push({
                store_info: {
                  ...product.store,
                  totalFees : (product.product.price_info.final_price  * Number(product.qty))
                },
                store_products: [{
                  ...product.product,
                  store_id: product.store.id,
                  quantityInCart:  Number(product.qty),
                  totalProductPrice:  Number(product.qty) * product.product.price_info.final_price,
                }]
              })
            }
          }
        })
        this.$store.dispatch('cart/updateCart', carts)
      }).finally(() => {
        this.loadingStore = false
      })
    },
    getUpdatedProductsFromServer () {
      this.loadingStore = true
      /*  eslint-disable  */
      let allProductsInCart = []
      this.getProductsCart.forEach(dataInCart => {
        dataInCart.store_products.map( ({id, quantityInCart}) => {
          allProductsInCart.push({
            "product_id": id,
            "quantity": quantityInCart
          })
        })
      })
      marketplace.getUpdatedProductsFromServer({cart_products: allProductsInCart}).then(res => {
        /*  eslint-disable  */
        let carts = []
        res.data.data.forEach(product => {
          if (product.product) {
            const getStoreId = carts.findIndex(store => store.store_info.id === product.store.id)
            if (getStoreId > -1) {
              carts[getStoreId].store_products.push({
                ...product.product,
                store_id: product.store.id,
                quantityInCart: Number(product.qty),
                totalProductPrice: Number(product.qty) * product.product.price
              })
              carts[getStoreId].store_info = {
                ...carts[getStoreId].store_info,
                totalFees : Number(carts[getStoreId].store_info.totalFees) + ((product.product.tax ? Number(product.product.price * product.product.tax.percentage / 100) + Number(product.product.price) : product.product.price) * Number(product.qty) )
              }
            } else {
              carts.push({
                store_info: {
                  ...product.store,
                  totalFees: product.product.price_info.final_price
                },
                store_products: [{
                  ...product.product,
                  store_id: product.store.id,
                  quantityInCart:  Number(product.qty),
                  totalProductPrice:  Number(product.qty) * product.product.price_info.final_price
                }]
              })
            }
          }
        })
        this.$store.dispatch('cart/updateCart', carts)
      }).finally(() => {
        this.loadingStore = false
      })
    },
    changeWidth () {
      if (window.innerWidth >= 990) {
        this.selectedView = 'largeCart'
      } else {
        this.selectedView = 'smallCart'
      }
    }
  },
  created () {
    if(localStorage.getItem('userToken')) {
      this.getProducts()
    } else {
      this.getUpdatedProductsFromServer()
    }
  },
  mounted () {
    core.index()
    this.changeWidth()
    window.addEventListener('resize', this.changeWidth)
  }
}
</script>
<style>
</style>
