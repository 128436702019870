<template>
  <iq-card class="mb-5 iq-border-radius-20 overflow-hidden border-cart-card">
    <b-card-header class="cart-header-store pr-4">
      <b-row class="align-items-center jus">
        <div class="store-img ml-4">
          <img :src="productItem.store_info.image || require('@/assets/images/ibbil/ibbil-cover.jpg')" alt="img">
        </div>
        <div class="d-flex align-items-center">
          <h4 class="text-white font-weight-bold">{{productItem.store_info.name}}</h4>
          <div class="iq-border-radius-20 bg-white mr-3 d-flex justify-content-center align-items-center">
            <span class="min-quantity-items text-primary py-2 px-3">{{productItem.store_products.length}} {{ $t('main.product') }}</span>
          </div>
        </div>
      </b-row>
    </b-card-header>
    <b-card-body class="all-list-card mr-2 ml-1">
      <b-row   v-for="(product, key) in productItem.store_products"
               :key="key" class="mb-4 py-2 pb-3 align-items-center product-item-store" >
        <b-col class="imageView" cols="4">
          <img class="w-100 iq-border-radius-10" :src="product.featured_image" alt="img">
        </b-col>
        <b-col class="content mb-2" cols="6">
          <h4 class="font-weight-bold">{{product.name}}</h4>
          <h4 class="d-flex gap_1 align-items-center"><span class="font-weight-light text-muted">{{product.tax ? (product.price * product.tax.percentage / 100) + product.price : product.price}} {{ $t('marketplace.rs') }}</span></h4>
        </b-col>
        <b-col cols="2">
          <b-button
              v-if="!checkFavourite(product)"
              @click="addToFavourite(product)"
              variant="white"
              class="action-cart-button text-warning"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25.98" height="25.98" viewBox="0 0 25.98 25.98">
              <g id="Group_3437" data-name="Group 3437" transform="translate(-77.02 -270.389)">
                <g id="Rectangle_1436" data-name="Rectangle 1436" transform="translate(77.02 270.389)" fill="#fff" stroke="#9b9b9b" stroke-width="0.3">
                  <rect width="25.98" height="25.98" rx="3" stroke="none"/>
                  <rect x="0.15" y="0.15" width="25.68" height="25.68" rx="2.85" fill="none"/>
                </g>
                <path id="a3d8debed02931a950fd863a634d1955" d="M9.969,16.03a.5.5,0,0,1-.356-.145L5.006,11.277a3.76,3.76,0,0,1,4.963-5.63,3.77,3.77,0,0,1,4.963.311h0a3.77,3.77,0,0,1,0,5.319l-4.607,4.607A.5.5,0,0,1,9.969,16.03ZM7.663,5.863a2.737,2.737,0,0,0-1.95.807,2.767,2.767,0,0,0,0,3.9l4.256,4.251,4.256-4.251a2.767,2.767,0,0,0,0-3.9h0a2.757,2.757,0,0,0-3.9,0,.5.5,0,0,1-.712,0,2.737,2.737,0,0,0-1.95-.807Z" transform="translate(80.041 272.934)" fill="#9b9b9b"/>
              </g>
            </svg>

          </b-button>
          <b-button
              v-else
              @click="deleteProductFavourite(product)"
              variant="warning"
              class="action-cart-button text-warning"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25.98" height="25.98" viewBox="0 0 25.98 25.98">
              <g id="Group_3437" data-name="Group 3437" transform="translate(-77.02 -270.389)">
                <g id="Rectangle_1436" data-name="Rectangle 1436" transform="translate(77.02 270.389)" fill="#fff" stroke="#9b9b9b" stroke-width="0.3">
                  <rect width="25.98" height="25.98" rx="3" stroke="none"/>
                  <rect x="0.15" y="0.15" width="25.68" height="25.68" rx="2.85" fill="none"/>
                </g>
                <path id="a3d8debed02931a950fd863a634d1955" d="M9.969,16.03a.5.5,0,0,1-.356-.145L5.006,11.277a3.76,3.76,0,0,1,4.963-5.63,3.77,3.77,0,0,1,4.963.311h0a3.77,3.77,0,0,1,0,5.319l-4.607,4.607A.5.5,0,0,1,9.969,16.03ZM7.663,5.863a2.737,2.737,0,0,0-1.95.807,2.767,2.767,0,0,0,0,3.9l4.256,4.251,4.256-4.251a2.767,2.767,0,0,0,0-3.9h0a2.757,2.757,0,0,0-3.9,0,.5.5,0,0,1-.712,0,2.737,2.737,0,0,0-1.95-.807Z" transform="translate(80.041 272.934)" fill="#9b9b9b"/>
              </g>
            </svg>

          </b-button>
          <b-button
              @click="deleteProduct(product)"
              variant="white"
              class="action-cart-button text-danger"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25.98" height="25.98" viewBox="0 0 25.98 25.98">
              <g id="Group_3438" data-name="Group 3438" transform="translate(-46.01 -283.617)">
                <g id="Rectangle_1435" data-name="Rectangle 1435" transform="translate(46.01 283.617)" fill="#fff" stroke="#9b9b9b" stroke-width="0.3">
                  <rect width="25.98" height="25.98" rx="3" stroke="none"/>
                  <rect x="0.15" y="0.15" width="25.68" height="25.68" rx="2.85" fill="none"/>
                </g>
                <path id="da086273b974cb595139babd4da17772" d="M15.058,7.617l-.194,5.862a1.931,1.931,0,0,1-1.937,1.867H9.248A1.931,1.931,0,0,1,7.312,13.48L7.118,7.617a.484.484,0,0,1,.968-.032l.194,5.863a.968.968,0,0,0,.968.931h3.679a.968.968,0,0,0,.968-.933l.194-5.861a.484.484,0,0,1,.968.032ZM15.7,5.666a.484.484,0,0,1-.484.484H6.962a.484.484,0,1,1,0-.968h1.5a.618.618,0,0,0,.616-.556,1.448,1.448,0,0,1,1.445-1.3h1.128a1.448,1.448,0,0,1,1.445,1.3.618.618,0,0,0,.616.556h1.5a.484.484,0,0,1,.484.484ZM9.92,5.182h2.337a1.6,1.6,0,0,1-.123-.457.484.484,0,0,0-.481-.436H10.524a.484.484,0,0,0-.481.436,1.6,1.6,0,0,1-.124.457Zm.488,7.335V8.395a.484.484,0,1,0-.968,0v4.124a.484.484,0,1,0,.968,0Zm2.331,0V8.395a.484.484,0,1,0-.968,0v4.124a.484.484,0,1,0,.968,0Z" transform="translate(47.912 287.273)" fill="#9b9b9b"/>
              </g>
            </svg>

          </b-button>
        </b-col>
        <!--actions -->
        <b-col class="my-2" cols="5">
          <div class="d-flex align-items-center justify-content-center product-item-calculate py-1 px-3 ">
            <b-button  @click="increaseQuantity(product)" class="bg-white m-0 p-0 text-primary border-0 "><i class="las la-plus m-0 text-primary font-size-22"></i></b-button>
            <span class="font-size-20 m-0 px-3  text-primary ">{{product.quantityInCart}}</span>
            <b-button  @click="decreaseQuantity(product)" class="bg-white m-0 p-0  text-primary border-0 "><i class="las la-minus m-0 text-primary font-size-22"></i></b-button>
          </div>
        </b-col>
        <b-row>
          <b-col class="my-2" cols="12">
            <h4 class="d-flex gap_1 align-items-center text-primary font-weight-light w-100">
              {{ $t('marketplace.total') }} :  <span class="font-size-20 font-weight-bold text-muted">{{parseFloat(product.totalProductPrice).toFixed(2)}} {{ $t('marketplace.rs') }}</span>
            </h4>
          </b-col>
        </b-row>
      </b-row>
    </b-card-body>
    <div class="w-88 footer-cart-store bg-white py-4">
<div class="d-flex justify-content-center align-items-center mb-2">
  <p class="m-0 ml-4 p-0 font-size-22 font-weight-light text-primary">{{ $t('marketplace.total') }} :</p><span class="font-size-24 font-weight-bold text-muted">{{parseFloat(productItem.store_info.totalFees).toFixed(2)}} {{ $t('marketplace.rs') }}</span>
</div>
      <div class="d-flex justify-content-center align-items-center">
        <span @click="addInPayment(productItem.store_info.id)" >
          <b-button
              variant="primary"
              class="ml-2"
          >
            <span class="font-size-16 text-white">{{ $t('marketplace.continuePayment') }}</span>
          </b-button>
        </span>
        <router-link :to="{name:'storeProfile', params: {id: productItem.store_info.category_id , slogan: productItem.store_info.url}}">
        <b-button
            variant="outline-primary"
            class="mr-2"
        >
          <span class="font-size-16">{{ $t('marketplace.backToStore') }}</span>
        </b-button>
        </router-link>
      </div>
    </div>
  </iq-card>
</template>

<script>
import { core } from '@/config/pluginInit'
import cartFunctions from '@/mixins/cartFunctions'
import productFunctions from '@/mixins/productFunctions'
export default {
  name: 'smallCart',
  props: ['productItem'],
  mixins: [cartFunctions, productFunctions],
  data () {
    return {
      totalStorePrice: 788,
      rs: 'ر.س',
      quantity: 2
    }
  },
  methods: {
    // addInPayment (storeProducts) {
    //   // console.log('payload', payload)
    //   this.$store.dispatch('cart/addInPayment', storeProducts)
    // },
    // addInPayment (payload) {
    //   this.$router.push({ name: 'payment-actions', params: { info: payload } })
    // },
    // increaseQuantity (payload) {
    //   this.$store.dispatch('cart/increaseQuantity', payload)
    // },
    // decreaseQuantity (payload) {
    //   this.$store.dispatch('cart/decreaseQuantity', payload)
    // },
    // deleteProduct (payload) {
    //   this.$store.dispatch('cart/deleteProductInCart', payload)
    // },
    // addToFavourite (product) {
    //   this.$store.dispatch('cart/addToFavourite', product)
    // },
    // checkFavourite (product) {
    //   return this.$store.getters['cart/checkProductInFavourite'](product)
    // },
    // deleteProductFavourite (payload) {
    //   this.$store.dispatch('cart/deleteProductInFavourite', payload)
    // }
  },
  mounted () {
    core.index()
  }
}
</script>

<style>
.font-size-22{
  font-size: 22px !important;
}
.card-header.cart-header-store {
  background: #3F6854CC !important;
  padding-right: 10px !important;
}
.product-item-calculate{
  box-shadow: 0px 0px 12px #0000001C;
  border-radius: 13px;
}
.border-cart-card {
  border: 1px solid #3F6854CC !important;
}
.product-item-store{
  padding-bottom: 15px;
  padding-top: 13px;
  border-bottom: 1px solid #eeeeee;
}
.all-list-card .product-item-store:last-child{
  border-bottom: 0;
}
.store-img{
  width: 78px !important;
  height: 78px !important;
  border-radius: 10px;
  overflow: hidden;
}
.store-img img{
  background-size: cover;
  width: 100%;
  height: 100%;
}
.prod-img{
  width: 84px !important;
  height: 84px !important;
  border-radius: 10px;
  border: 1px solid #DBDBDB;
  overflow: hidden;
}
.prod-img img{
  background-size: cover;
  width: 100%;
  height: 100%;
}
.delete-item-cart {
  background: #EC5D61;
  border-radius: 50%;
  width: 29px;
  height: 29px;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}
.footer-cart-store{
  border-top: 3px solid #b4b6b7;
}
.w-88 {
  width: 88%;
  margin: auto;
}
@media (max-width: 768px) {
  .store-img h2{
    font-size: 22px !important;
  }
  .min-quantity-items{
    font-size: 18px !important;
  }
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.action-cart-button {
  width: 45px !important;
  height: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  border-radius: 5px !important;
  margin-bottom: 5px !important;
}
.action-cart-button svg{
  width: 100%;
  height: 100%;
}
</style>
