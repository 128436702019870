<template>
  <iq-card class="mb-5 iq-border-radius-20 overflow-hidden border-cart-card">
    <b-card-header class="cart-header-store pr-4">
      <b-row class="align-items-center">
        <div class="store-img ml-4">
          <img :src="productItem.store_info.logo || require('@/assets/images/ibbil/ibbil-cover.jpg')" alt="img"
               :style="{ 'background-color': '#fff'}"
          >
        </div>
        <div class="d-flex align-items-center">
          <h2 class="text-white font-weight-bold"> {{productItem.store_info.name}}</h2>
          <div class="iq-border-radius-20 bg-white mr-3 d-flex justify-content-center align-items-center">
            <span class="min-quantity-items text-primary font-size-20 py-2 px-4">{{productItem.store_products.length}} {{ $t('main.product') }}</span>
          </div>
        </div>
      </b-row>
    </b-card-header>
    <b-card-body class="all-list-card mr-3">

      <b-row class="product-item-store align-items-center"
                   v-for="(product, key) in productItem.store_products"
                   :key="key"
            >
        <b-col md="3">
                <div class="d-flex align-items-center">
                  <div class="prod-img">
                    <img :src="product.featured_image" alt="img">
                  </div>
                  <h4 class="text-primary font-weight-bold mr-4">{{product.name}}</h4>
                </div>
              </b-col>
        <b-col md="2">
                <div class="d-flex align-items-center">
                  <h4 class="m-0 ml-4 p-0 text-primary font-weight-light">{{ $t('marketplace.price') }} :  <span class="font-weight-light text-muted">{{product.price_info.final_price}} {{ $t('marketplace.rs') }}</span></h4>
                </div>
              </b-col>
        <b-col md="4" class="p-0">
                <div class="d-flex align-items-center justify-content-center m-0">
                  <p class="m-0 ml-4 p-0 font-size-18 text-primary">{{ $t('marketplace.quantity') }}</p>
                  <b-col md="2" class="product-item-calculate py-2 px-5 d-flex justify-content-center align-items-center">
                    <b-button @click="increaseQuantity(product)" class="bg-white m-0 p-0 text-primary border-0 "><i class="las la-plus m-0 text-primary font-size-20"></i></b-button>
                    <span class="font-size-20 m-0 px-3  text-primary ">{{product.quantityInCart}}</span>
                    <b-button @click="decreaseQuantity(product)" class="bg-white m-0 p-0  text-primary border-0 "><i class="las la-minus m-0 text-primary font-size-20"></i></b-button>
                  </b-col>
                  <h4 class="m-0 mr-5 p-0 text-primary font-weight-light">{{ $t('marketplace.total') }} :  <span class="font-size-20 font-weight-bold text-muted">{{parseFloat(product.totalProductPrice).toFixed(2)}} {{ $t('marketplace.rs') }}</span> </h4>
                </div>
              </b-col>
        <b-col md="3">
                <div class="d-flex align-items-center justify-content-end">
                  <b-button
                      v-if="!checkFavourite(product)"
                      @click="addToFavourite(product)"
                      variant="outline-primary"
                      class="product-item-to-favourite px-4"
                  >
                    <span class="font-size-16">{{ $t('marketplace.saveToFavourite') }}</span>
                  </b-button>
                  <b-button
                      v-else
                      @click="deleteProductFavourite(product)"
                      variant="outline-warning"
                      class="product-item-to-favourite px-4"
                  >
                    <span class="font-size-16">{{ $t('marketplace.removeFromFavourite') }}</span>
                  </b-button>
                  <b-button
                      @click="deleteProductInCart(product)"
                      variant="white"
                      class="action-cart-button text-danger mr-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25.98" height="25.98" viewBox="0 0 25.98 25.98">
                      <g id="Group_3438" data-name="Group 3438" transform="translate(-46.01 -283.617)">
                        <g id="Rectangle_1435" data-name="Rectangle 1435" transform="translate(46.01 283.617)" fill="#fff" stroke="#9b9b9b" stroke-width="0.3">
                          <rect width="25.98" height="25.98" rx="3" stroke="none"/>
                          <rect x="0.15" y="0.15" width="25.68" height="25.68" rx="2.85" fill="none"/>
                        </g>
                        <path id="da086273b974cb595139babd4da17772" d="M15.058,7.617l-.194,5.862a1.931,1.931,0,0,1-1.937,1.867H9.248A1.931,1.931,0,0,1,7.312,13.48L7.118,7.617a.484.484,0,0,1,.968-.032l.194,5.863a.968.968,0,0,0,.968.931h3.679a.968.968,0,0,0,.968-.933l.194-5.861a.484.484,0,0,1,.968.032ZM15.7,5.666a.484.484,0,0,1-.484.484H6.962a.484.484,0,1,1,0-.968h1.5a.618.618,0,0,0,.616-.556,1.448,1.448,0,0,1,1.445-1.3h1.128a1.448,1.448,0,0,1,1.445,1.3.618.618,0,0,0,.616.556h1.5a.484.484,0,0,1,.484.484ZM9.92,5.182h2.337a1.6,1.6,0,0,1-.123-.457.484.484,0,0,0-.481-.436H10.524a.484.484,0,0,0-.481.436,1.6,1.6,0,0,1-.124.457Zm.488,7.335V8.395a.484.484,0,1,0-.968,0v4.124a.484.484,0,1,0,.968,0Zm2.331,0V8.395a.484.484,0,1,0-.968,0v4.124a.484.484,0,1,0,.968,0Z" transform="translate(47.912 287.273)" fill="#9b9b9b"/>
                      </g>
                    </svg>
                  </b-button>
                </div>
              </b-col>
      </b-row>

    </b-card-body>
    <div class=" w-88 m-auto py-4 footer-cart-store bg-white">
      <div class="d-flex justify-content-end align-items-center">
        <p class="m-0 ml-4 p-0 font-size-22 font-weight-light text-primary">{{ $t('marketplace.total') }} :</p><span class="font-size-24 font-weight-bold text-muted">{{parseFloat(productItem.store_info.totalFees).toFixed(2)}} {{ $t('marketplace.rs') }}</span>
        <span @click="addInPayment(productItem.store_info.id)" >
          <b-button
              variant="primary"
              class="mr-5 px-5"
          >
            <span class="font-size-16 text-white">{{ $t('marketplace.continuePayment') }}</span>
          </b-button>
        </span>
        <router-link :to="{name:'storeProfile', params: {id: productItem.store_info.url , slogan: productItem.store_info.url}}">
        <b-button
            variant="outline-primary"
            class="mr-4 px-5"
        >
          <span class="font-size-16">{{ $t('marketplace.backToStore') }}</span>
        </b-button>
        </router-link>
      </div>
    </div>
  </iq-card>
</template>

<script>
import { core } from '@/config/pluginInit'
import cartFunctions from '@/mixins/cartFunctions'
import productFunctions from '@/mixins/productFunctions'
export default {
  name: 'largeCart',
  props: ['productItem'],
  mixins: [cartFunctions, productFunctions],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  mounted () {
    core.index()
  }
}
</script>

<style>
.font-size-22{
  font-size: 22px !important;
}
.card-header.cart-header-store {
  background: #3F6854CC !important;
  padding-right: 10px !important;
}
.product-item-calculate{
  box-shadow: 0px 0px 12px #0000001C;
  border-radius: 13px;
}
.border-cart-card {
  border: 1px solid #3F6854CC !important;
}
.product-item-store{
  padding-bottom: 15px;
  padding-top: 13px;
  border-bottom: 1px solid #eeeeee;
}
.all-list-card .product-item-store:last-child{
  border-bottom: 0;
}
.store-img{
  width: 78px !important;
  height: 78px !important;
  border-radius: 10px;
  overflow: hidden;
}
.store-img img{
  background-size: cover;
  width: 100%;
  height: 100%;
}
.prod-img{
  width: 84px !important;
  height: 84px !important;
  border-radius: 10px;
  border: 1px solid #DBDBDB;
  overflow: hidden;
}
.prod-img img{
  background-size: cover;
  width: 100%;
  height: 100%;
}
/*.delete-item-cart {*/
/*  background: #EC5D61;*/
/*  border-radius: 50%;*/
/*  width: 29px;*/
/*  height: 29px;*/
/*  font-size: 20px;*/
/*  font-weight: bold;*/
/*  color: #FFFFFF;*/
/*}*/
.footer-cart-store{
  border-top: 3px solid #b4b6b7;
}
.w-88 {
  width: 88%;
  margin: auto;
}
@media (max-width: 768px) {
  .store-img h2{
    font-size: 22px !important;
  }
  .min-quantity-items{
    font-size: 18px !important;
  }
}
</style>
