import { core } from '@/config/pluginInit'
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
export default {
  data () {
    return {
    }
  },
  methods: {
    addInPayment (id) {
      if (this.checkIfLogin()) {
        // const carts = this.$store.getters['cart/getProductCart']
        // marketPlace.postProductInCart({ carts: carts }).then(res => {
        //   // core.showSnackbar('success', res.data.message)
        // })
        //   .catch(err => {
        //     console.log((err))
        //   })
        this.$router.push({ name: 'payment-actions', params: { id: id } })
      } else {
        this.$router.push({ name: 'login' })
      }
    },
    increaseQuantity (payload) {
      const productCart = {
        store_info: { id: payload.store_id },
        store_products: [{ id: payload.id, quantityInCart: payload.quantityInCart + 1 }]
      }
      if (localStorage.getItem('userToken')) {
        marketPlace.postProductInCart({ carts: [productCart] }).then(res => {
          core.showSnackbar('success', 'تم إضافة منتج اخر')
          this.$store.dispatch('cart/increaseQuantity', payload)
        })
          .catch(err => {
            console.log((err))
          })
      } else {
        core.showSnackbar('success', 'تم إضافة منتج اخر')
        this.$store.dispatch('cart/increaseQuantity', payload)
      }
    },
    decreaseQuantity (payload) {
      if (payload.quantityInCart !== 1) {
        if (localStorage.getItem('userToken')) {
          const productCart = {
            store_info: { id: payload.store_id },
            store_products: [{ id: payload.id, quantityInCart: payload.quantityInCart - 1 }]
          }
          marketPlace.postProductInCart({ carts: [productCart] }).then(res => {
            core.showSnackbar('success', 'تم إزالة منتج من العربة')
            this.$store.dispatch('cart/decreaseQuantity', payload)
          })
            .catch(err => {
              console.log((err))
            })
        } else {
          core.showSnackbar('success', 'تم إزالة منتج من العربة')
          this.$store.dispatch('cart/decreaseQuantity', payload)
        }
      } else {
        core.showSnackbar('success', 'لا يمكن ان تقل الكمية عن 1')
      }
    },
    deleteProductInCart (payload) {
      if (localStorage.getItem('userToken')) {
        marketPlace.deleteProductInCart(payload.id).then(() => {
          core.showSnackbar('success', 'تم إزالة منتج من العربة')
          this.deleteProduct(payload)
        })
      } else {
        this.deleteProduct(payload)
      }
    },
    deleteProduct (payload) {
      this.$store.dispatch('cart/deleteProductInCart', payload)
      core.showSnackbar('success', 'تم إزالة منتج من العربة')
    }
    // addToFavourite (product) {
    //   this.$store.dispatch('cart/addToFavourite', product)
    // },
    // checkFavourite (product) {
    //   return this.$store.getters['cart/checkProductInFavourite'](product)
    // },
    // deleteProductFavourite (payload) {
    //   this.$store.dispatch('cart/deleteProductInFavourite', payload)
    // }
  },
  created () {
    // this.getCartFromBackend()
  }
}
